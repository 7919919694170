import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import ReceiptViewGateway from '../../../gateway/Receipt/ReceiptViewGateway'
import {ReceiptView} from '../../../domain/Receipt/ReceiptView'
import {IntegrationStateHistoryInterface} from '../../../domain/IntegrationStateHistory/IntegrationStateHistory'
import GetIntegrationStateHistoryUseCase from '../../../useCase/Folder/GetIntegrationStateHistoryUseCase'
import IntegrationStateHistoryGateway from '../../../gateway/Folder/IntegrationStateHistoryGateway'
import {Tab, Tabs} from '../tab/Tab'
import IntegrationStateHistoryBlock from '../integrationState/IntegrationStateHistoryBlock'
import ReceiptsValidation from '../integrationState/ReceiptsValidation'

// Styles
import '../../../assets/styles/components/_validation.scss'
import {CustomerInfoRecallInterface} from '../../../domain/CustomerInfoRecall/CustomerInfoRecall'
import RetrieveCustomerInfoRecallUseCase from '../../../useCase/Folder/RetrieveCustomerInfoRecallUseCase'
import CustomerInfoRecallGateway from '../../../gateway/Folder/CustomerInfoRecallGateway'
import Pairing from '../integrationState/Pairing'
import {PotentialCustomerInterface} from '../../../domain/PotentialCustomer/PotentialCustomer'
import ViewDossierGateway from '../../../gateway/ViewDossier/ViewDossierGateway'
import RetrieveViewDossierUseCase from '../../../useCase/Folder/RetrieveViewDossierUseCase'
import PresentationDossier from '../integrationState/PresentationDossier'
import {ViewPageInterface} from '../../../domain/ViewDossier/ViewPage'
import FetchFolderGateway from '../../../gateway/Folder/FetchFolderGateway'
import { SignedDocument } from '../../../domain/Docusign/SignedDocument'
import GetListSignedDocumentUseCase from '../../../useCase/Docusign/GetListSignedDocumentUseCase'
import FetchSignedDocumentGateway from '../../../gateway/Docusign/FetchSignedDocumentGateway'
import { FolderDetail } from '../../../domain/Folder/Folder'
import FolderDetails from '../folder/FolderComponent/FolderDetails'
import { SubscriberScopeEnum } from '../../../domain/CustomerInfoRecall/SubscriberScopeEnum'

type Props = {
  dossierId: string
}

const BlockValidationSummaryComponent: FunctionComponent<Props> = ({dossierId}) => {
  const {t} = useTranslation()

  const [dossierSummary, setDossierSummary] = useState<FolderDetail | null>(null)
  const [documents, setDocuments] = useState<SignedDocument[] | null>([])
  const [receipts, setReceipts] = useState<{ [key: string]: ReceiptView[] } | null>(null)
  const [integrationStateHistory, setIntegrationStateHistory] = useState<IntegrationStateHistoryInterface[] | null>(null)
  const [customerInfoRecall, setCustomerInfoRecall] = useState<CustomerInfoRecallInterface | null>(null)
  const [potentialCustomerList, setPotentialCustomerList] = useState<PotentialCustomerInterface[] | null>(null)
  const [dossierPresentation, setDossierPresentation] = useState<ViewPageInterface[] | null>(null)
  const [state, setState] = useState<string | null>(null)
  const [folderState, setFolderState] = useState<string | null>(null)

  useEffect(() => {
    new FetchFolderGateway().getSummary(dossierId).then((response) => {
      setDossierSummary(response)
    })

    new FetchFolderGateway().retrieveState(dossierId).then((response) => {
      const state = t(`common.${response}`)
      setState(state)
      setFolderState(response)
    })

    new ReceiptViewGateway().retrieveList(dossierId).then((response) => {
      setReceipts(response)
    })

    new GetListSignedDocumentUseCase(new FetchSignedDocumentGateway()).execute(dossierId).then((response) => {
      setDocuments(response)
    })

    const getIntegrationStateHistoryUseCase = new GetIntegrationStateHistoryUseCase(new IntegrationStateHistoryGateway())
    getIntegrationStateHistoryUseCase.handle({dossierId: dossierId}).then((response) => {
      setIntegrationStateHistory(response)
    })

    const retrieveCustomerInfoRecallUseCase = new RetrieveCustomerInfoRecallUseCase(new CustomerInfoRecallGateway())
    retrieveCustomerInfoRecallUseCase.handle({dossierId: dossierId, scope:SubscriberScopeEnum.NP}).then((response) => {
      if (response !== null) {
        setCustomerInfoRecall(response.customerInfoRecall)
        setPotentialCustomerList(response.potentialCustomerList)
      }
    })

    const retrieveViewDossierUseCase = new RetrieveViewDossierUseCase(new ViewDossierGateway())
    retrieveViewDossierUseCase.handle({dossierId: dossierId}).then((response) => {
      if (response !== null) {
        setDossierPresentation(response)
      }
    })
    
  }, [dossierId])

  const reloadHistory = () => {
    const getIntegrationStateHistoryUseCase = new GetIntegrationStateHistoryUseCase(new IntegrationStateHistoryGateway())
    getIntegrationStateHistoryUseCase.handle({dossierId: dossierId}).then((response) => {
      setIntegrationStateHistory(response)
    })
  };

  return (
    <>
      <div className="box-elevations box-elevations--base box-elevations--mb u-mxAuto u-pxm u-pym">
        <h4 className={`u-mb0`}>
          { dossierSummary && <FolderDetails FolderDetail={dossierSummary} error={false} FolderStatuses={state ? [state]:[]} />}
        </h4>
      </div>
    <Tabs classes="wrap u-mxAuto">
      {dossierPresentation !== null &&
        <Tab key="folder" label={t('validation.presentation-dossier')}>
          <PresentationDossier dossierId={dossierId} viewPages={dossierPresentation} />
        </Tab>
      }
      {receipts !== null &&
        <Tab key="receipt" label={t('validation.receipts')}>
          <ReceiptsValidation dossierId={dossierId} receipts={receipts} documents={documents} />
        </Tab>
      }
      {customerInfoRecall !== null && (folderState === 'ongoing' || folderState === 'error') && 
        <Tab key="pairing" label={t('validation.pairing-unicia')}>
          <Pairing dossierId={dossierId} customerInfoRecall={customerInfoRecall} potentialCustomerList={potentialCustomerList} scope={SubscriberScopeEnum.NP} onModify={reloadHistory}/>
        </Tab>
      }
      {integrationStateHistory !== null &&
        <Tab key="history" label={t('validation.actions')}>
          <IntegrationStateHistoryBlock integrationStateHistory={integrationStateHistory} />
        </Tab>
      }
    </Tabs>
    </>
  )
}

export default BlockValidationSummaryComponent

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomerInfoRecallInterface } from '../../../domain/CustomerInfoRecall/CustomerInfoRecall';
import MoralPerson from './partials/MoralPerson';
import PhysicalPerson from './partials/PhysicalPerson';
import { PotentialCustomerInterface } from '../../../domain/PotentialCustomer/PotentialCustomer';
import PhysicalPersonLineTable from './partials/PhysicalPersonLineTable'
import MoralPersonLineTable from './partials/MoralPersonLineTable'
import PairUseCase from '../../../useCase/Folder/PairUseCase'
import CustomerInfoRecallGateway from '../../../gateway/Folder/CustomerInfoRecallGateway'
import {ENotificationType} from '../notification/ENotificationType'
import Notification from '../notification/Notification'
import SelectedTickMark from '../svg/SelectedTickMark';
import ManuelPersonSelectionLineTable from './partials/ManuelPersonSelectionLineTable';
import FetchFolderGateway from '../../../gateway/Folder/FetchFolderGateway';
import GetFolderPairingUseCase from '../../../useCase/Folder/GetFolderPairingUseCase';

interface Props {
  dossierId: string
  customerInfoRecall: CustomerInfoRecallInterface
  potentialCustomerList: PotentialCustomerInterface[] | null
  scope: string
  onModify?: ()=> void;
}

const Pairing: React.FC<Props> = ({ dossierId, customerInfoRecall, potentialCustomerList, scope, onModify }) => {
  const { t } = useTranslation();
  const [success, setSuccess] = React.useState(false);
  const [manualSelectedCustomer, setManualSelectedCustomer] = React.useState<PotentialCustomerInterface|null>(null)
  const [selectedUsername, setSelectedUsername] = React.useState<string|null>(null)

  const handleSelectCustomer = (username: string|null) => {
    const pairUseCase = new PairUseCase(new CustomerInfoRecallGateway())
    pairUseCase.handle({ dossierId: dossierId, username: username, scope: scope }).then((response) => {
      if (response) {
        setManualSelectedCustomer(null)
        setSelectedUsername(username)
        setSuccess(false)
        setSuccess(true)
        if (onModify)
          onModify()
      }
    })
  };

  const handleCustomUserSelection = (potentialUser: PotentialCustomerInterface) => {
    const listedUser = potentialCustomerList?.filter(customer => customer.username === potentialUser.username);
    if (listedUser?.length === 0) {
      const pairUseCase = new PairUseCase(new CustomerInfoRecallGateway())
      pairUseCase.handle({ dossierId: dossierId, username: potentialUser.username, scope: scope }).then((response) => {
        if (response) {
          setManualSelectedCustomer(potentialUser)
          setSelectedUsername(potentialUser.username)
          setSuccess(true)
          if (onModify)
            onModify()  
        }
      })        
    } else {
      handleSelectCustomer(potentialUser.username)
    }
  }

  useEffect(() => {
    if (success) {
        setTimeout(() => setSuccess(false), 3000)
    }
  }, [success])

  useEffect(() => {
    const pairingUseCase = new GetFolderPairingUseCase(new FetchFolderGateway())
    pairingUseCase.handle(dossierId).then((response) => {
      if (response) {
        setSelectedUsername(response[scope] ?? null)
      }
    })
  }, [dossierId, scope])

  return (
    <div className="box-elevations box-elevations--base box-elevations--mb u-mxAuto">
      {success &&
        <div className="u-mbm">
          <Notification label={t('validation.pair-success')} type={ENotificationType.success}/>
        </div>
      }
      <h2 className="help-wrapper u-mbs">
        {t('validation.subscriber.'+scope)}
      </h2>
      <h3 className="h5 u-mbs">{t('validation.customer-info-recall')}</h3>
      {
        customerInfoRecall.isMoralPerson
          ? <MoralPerson customerInfoRecall={customerInfoRecall}/>
          : <PhysicalPerson customerInfoRecall={customerInfoRecall}/>
      }
      <h3 className="h5 u-mtl">{t('validation.potential-customer-list')}</h3>
        <div className="table-container">
          <table className="table">
            <tbody>
              <tr key={'new-customer'}>
                <td className="">
                </td>
                <td className="">
                  {t('validation.create-new-customer')}
                </td>
                <td className="">
                </td>
                <td className="">
                </td>
                <td className={"form-control-center"}>
                  { !selectedUsername ? <SelectedTickMark color={"var(--ink-1)" } /> : (
                  <button type="button"
                          className="button button-primary--outline button--medium form-control-center"
                          onClick={() => handleSelectCustomer(null)}>
                    {t('validation.select-customer')}
                  </button>
                  )}
                </td>
              </tr>
            {potentialCustomerList && potentialCustomerList.map((potentialCustomer) => (
              <tr key={potentialCustomer.username}>
                {
                  potentialCustomer.isMoralPerson
                    ? <MoralPersonLineTable potentialCustomer={potentialCustomer} selectedUsername={selectedUsername} onSelectCustomer={handleSelectCustomer}/>
                    : <PhysicalPersonLineTable potentialCustomer={potentialCustomer} selectedUsername={selectedUsername} onSelectCustomer={handleSelectCustomer}/>
                }
              </tr>
            ))}
              <tr key={"manual-selection"}>
                <ManuelPersonSelectionLineTable potentialCustomer={manualSelectedCustomer} onSelectCustomerByCode={handleSelectCustomer} selectedUsername={selectedUsername} onSelectCustomer={handleCustomUserSelection} />
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  );
};

export default Pairing;
